import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  navigateToStep,
  nextStep,
  updateForm,
} from "../../../../store/slices/registrationFormSlice";
import OTPInput, { InputProps } from "react-otp-input";
import { GetWindowDimensions } from "../../../../hooks/window-hooks/WindowHooks";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import { RegisterAPI } from "../../../../services/authService";
import {
  sendOtp,
  signupPAFDetail,
  verifyOtp,
} from "../../../../store/thunks/authThunks";
// import { sendOtp } from "../../../../store/slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader/Loader";
import UserIcon from "../../../../images/authentication/user-circle-icon.svg";
import MailIcon from "../../../../images/authentication/mail-icon.svg";
import PhoneIcon from "../../../../images/authentication/phone-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckSquare,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { agreeTC } from "../../../../store/slices/authSlice";
import axios from "axios";
import config from "../../../../util/config";

interface PersonalInfoFormInputProps {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  otp: string;
}

const PersonalInfoForm = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const { width } = GetWindowDimensions();

  const { firstName, lastName, email, mobileNumber, otp } = useSelector(
    (state: RootState) => state.registrationFormSlice.data
  );
  const { step } = useSelector(
    (state: RootState) => state.registrationFormSlice
  );

  const { otpSent, error, message, loading, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  const [isVerifyOtp, setIsVerifyOtp] = useState(false);
  const [otpValue, setOtp] = useState(otp);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isValid },
    trigger,
  } = useForm<PersonalInfoFormInputProps>({
    mode: "onChange", // Validate on every change
  });

  // const onSubmit: SubmitHandler<PersonalInfoFormInputProps> = (data) => {
  //   console.log("SubmitHandler", data);
  //   dispatch(nextStep());
  // };

  const onSubmit = (data: any) => {
    dispatch(updateForm({ ...data, otp: otpValue }));
    dispatch(nextStep());
    // dispatch(navigateToStep({ step: step + 1, navigate }));
  };

  const mobile = watch("mobileNumber");

  const handleVerifyOtp = () => {
    let SignUpPAFInfoPayload = {
      streamId: 0,
      courseId: 0,
      state: 0,
      city: 0,

      currentStudy: "",

      lookingFor: "",
      higherEducation: "",
      educationFund: "",
      importantFactor: "",
      boardOfEducation: "",
    };
    // add logic for otp verify
    if (otpValue) {
      dispatch(verifyOtp({ mobile, otp: otpValue }))
        .unwrap()
        .then(() => {
          dispatch(signupPAFDetail(SignUpPAFInfoPayload));
        })
        .catch((err: any) => {
          console.error("OTP verification failed:", err);
        });
    }
    setIsVerifyOtp(!isVerifyOtp);
  };

  const handleSendOTP = () => {
    if (mobile?.length == 10) {
      dispatch(sendOtp({ mobile }));
    } else {
      console.log("Enter 10 digit Mobile number");
    }
  };

  // const toastId = useRef<number | string | null>(null);

  // useEffect(() => {
  //   if (message) {
  //     toast.success(message);
  //   }
  // }, [message]);

  // useEffect(() => {
  //   if (error) {
  //     toast.error(error);
  //   }
  // }, [error]);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    dispatch(agreeTC());
  };

  const handleNext = () => {
    dispatch(nextStep());
  };
  const [registered, setRegistered] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  console.log("isValid", errors);
  useEffect(() => {
    const checkMobileNumber = async () => {
      if (mobile?.length === 10) {
        try {
          const response = await axios.get(
            config.apiUrl + `/api/auth/user/details/${mobile}`
          );
          if (response?.data?.data?.mobileVerificationStatus) {
            setRegistered(true);
          } else {
            setRegistered(false);
          }
        } catch (error) {
          setRegistered(true);
        }
      }
    };

    checkMobileNumber();
  }, [mobile]);

  const focusedStyle = {
    border: "2px solid #007BFF",
  };

  const inputStyle: React.CSSProperties = {
    width: "50px",
    height: "50px",
    margin: "0 5px",
    fontSize: "18px",
    textAlign: "center" as const, // Explicitly cast to 'center'
    border: "1px solid #ccc",
    borderRadius: "8px",
    outline: "none",
    transition: "border-color 0.3s ease",
  };
  return (
    <div className="flex flex-col w-full gap-2">
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={true}
        showBack={false}
        stepName="Register"
        stepDescription="We at CollegeMentor aim to bridge the gap between colleges and students through mentorship"
      >
        <div
          className="flex flex-col max-w-lg gap-4 mb-6"
          // onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex gap-3 max-sm:flex-wrap md:flex-nowrap">
            <div className="w-full md:w-1/2 flex flex-col gap-1.5">
              {/* <label
                className="block mb-2 text-xs font-bold tracking-wide text-black-700"
                htmlFor="grid-first-name"
              >
                First Name
              </label>
              <div>
                <div className="flex items-center px-3 py-3 border-2 border-grey-300 rounded focus-within:border-[#113cc0]">
                  <img src={UserIcon} width="20" height="20" />

                  <input
                    className="block w-full pl-2 leading-tight text-gray-700 outline-none "
                    id="grid-first-name"
                    type="text"
                    placeholder="First name"
                    defaultValue={firstName}
                    {...register("firstName")}
                  />
                </div>
              </div> */}
              <label
                htmlFor="input-field"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <div className="relative rounded-sm shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <img src={UserIcon} alt="user-icon" width="20" height="20" />
                </div>
                <input
                  type="text"
                  id="input-field"
                  className="block w-full py-2 pl-10 pr-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="First name"
                  defaultValue={firstName}
                  // {...register("firstName")}
                  {...register("firstName", {
                    required: "First name is required",
                    maxLength: {
                      value: 10,
                      message: "First name cannot exceed 10 characters",
                    },
                  })}
                  onChange={async (e) => {
                    setValue("firstName", e.target.value);
                    await trigger("firstName");
                  }}
                />
              </div>
              <p className="text-xs italic text-red-600 text-right">
                {errors.firstName && <p>{errors?.firstName?.message}</p>}
              </p>

              {/* old input field */}
              {/* <input
                className="pl-10 block w-full px-4 py-3 leading-tight text-gray-700 border-2 rounded appearance-none focus:outline-none focus:bg-white focus:border-[#113cc0]"
                id="grid-first-name"
                type="text"
                placeholder="First name"
                defaultValue={firstName}
                {...register("firstName")}
              /> */}
            </div>

            <div className="w-full md:w-1/2 flex flex-col gap-1.5">
              {/* <label
                className="block mb-2 text-xs font-bold tracking-wide text-black-700 "
                htmlFor="grid-last-name"
              >
                Last Name
              </label>

              <div className="flex items-center px-3 py-3 border-2 border-gray-300 rounded focus-within:border-[#113cc0]">
                <img src={UserIcon} width="20" height="20" />

                <input
                  className="block w-full pl-2 leading-tight text-gray-700 outline-none "
                  id="grid-last-name"
                  type="text"
                  placeholder="Last name"
                  defaultValue={lastName}
                  {...register("lastName")}
                /> */}
              <label
                htmlFor="input-field"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <div className="relative rounded-sm shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <img src={UserIcon} alt="user-icon" width="20" height="20" />
                </div>
                <input
                  type="text"
                  id="input-field"
                  className="block w-full py-2 pl-10 pr-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Last name"
                  defaultValue={lastName}
                  // {...register("lastName")}
                  {...register("lastName", {
                    required: "Last name is required",
                    maxLength: {
                      value: 10,
                      message: "Last name cannot exceed 10 characters",
                    },
                  })}
                  onChange={async (e) => {
                    setValue("lastName", e.target.value);
                    await trigger("lastName");
                  }}
                />
              </div>{" "}
              <p className="text-xs italic text-red-600 text-right">
                {errors.lastName && <p>{errors?.lastName?.message}</p>}
              </p>
              {/* <input
                className="block w-full px-4 py-3 leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-[#113cc0]"
                id="grid-last-name"
                type="text"
                placeholder="Last name"
                defaultValue={lastName}
                {...register("lastName")}
              /> */}
            </div>
          </div>

          <div className="w-full flex flex-col gap-1.5">
            {/* <label
              htmlFor="email"
              className="block mb-2 text-xs font-bold tracking-wide text-black-700"
            >
              Email
            </label>
            <div className="flex items-center px-3 py-3 border-2 border-gray-300 rounded focus-within:border-[#113cc0]">
              <img src={MailIcon} width="20" height="20" />

              <input
                className="block w-full pl-2 leading-tight text-gray-700 outline-none "
                type="email"
                id="email"
                placeholder="name@company.com"
                {...register("email")}
                defaultValue={email}
              /> */}

            <label
              htmlFor="input-field"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <div className="relative rounded-sm shadow-sm">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <img src={MailIcon} alt="mail-icon" width="20" height="20" />
              </div>
              <input
                type="email"
                id="email"
                placeholder="name@company.com"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Enter a valid email address",
                  },
                })}
                // {...register("email")}
                // defaultValue={email}
                onChange={async (e) => {
                  setValue("email", e.target.value);
                  await trigger("email");
                }}
                className="block w-full py-2 pl-10 pr-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <p className="text-xs italic text-red-600 text-right">
              {errors.email && <p>{errors?.email?.message}</p>}
            </p>

            {/* <p className="text-xs italic text-gray-600">
              Make it as long and as crazy as you'd like
            </p> */}
          </div>

          <div className="flex flex-col gap-3">
            <div className="w-full flex flex-col gap-1.5">
              <label
                htmlFor="mobileNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Mobile Number
              </label>
              <div className="relative rounded-sm shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <img src={PhoneIcon} alt="phoneicon" width="20" height="20" />
                </div>
                <input
                  type="text"
                  id="mobileNumber"
                  placeholder="1234567890"
                  {...register("mobileNumber", {
                    required: "Mobile number is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Mobile number must be exactly 10 digits",
                    },
                  })}
                  value={watch("mobileNumber") || ""} // Ensure the input is controlled
                  onChange={(e) => {
                    const onlyNumbers = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    if (onlyNumbers.length <= 10) {
                      setValue("mobileNumber", onlyNumbers, {
                        shouldValidate: true,
                      });
                    }
                  }}
                  disabled={isAuthenticated}
                  maxLength={10} // Ensures input does not exceed 10 digits
                  className="block w-full py-2 pl-10 pr-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              {mobile?.length === 10 && registered && (
                <p className="text-xs italic text-red-600 text-right">
                  Number is already registered, try with another number or login
                  directly
                </p>
              )}

              {/* <label
                htmlFor="mobileNumber"
                className="block mb-2 text-xs font-bold tracking-wide text-black-700"
              >
                Mobile Number
              </label>

              <div className="flex items-center px-3 py-3 border-2 border-grey-700 rounded focus-within:border-[#113cc0]">
                <img src={PhoneIcon} width="20" height="20" />

                <input
                  className="block w-full pl-2 leading-tight text-gray-700 outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none "
                  type="number"
                  id="mobileNumber"
                  placeholder="123456789"
                  {...register("mobileNumber", {
                    required: "Mobile number is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Mobile number must be exactly 10 digits",
                    },
                  })}
                  defaultValue={mobileNumber}
                  onChange={async (e) => {
                    setValue("mobileNumber", e.target.value);
                    await trigger("mobileNumber");
                  }}
                />
              </div> */}

              <p className="text-xs italic text-red-600 text-right">
                {errors.mobileNumber && <p>{errors.mobileNumber.message}</p>}
              </p>
              {width < 700 && (
                <>
                  {mobile?.length === 10 && !otpSent && (
                    <p className="flex justify-end text-xs italic text-gray-600">
                      <button
                        className="text-nowrap px-4 py-2 text-sm text-center text-[#113cc0] transition-all border border-transparent rounded-md text-slate-600 hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                        onClick={handleSendOTP}
                      >
                        {loading ? <Loader /> : "Send OTP"}
                      </button>
                    </p>
                  )}
                </>
              )}
            </div>
            {!registered &&
              Object.keys(errors).length === 0 &&
              !errors.email &&
              mobile?.length === 10 &&
              !otpSent && (
                <>
                  {width > 700 && (
                    <div className="flex items-end justify-end w-full">
                      <button
                        className="text-nowrap  font-semibold px-4 py-2 text-sm text-center text-[#113cc0] transition-all border border-transparent rounded-md text-slate-600 hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                        onClick={handleSendOTP}
                      >
                        Send OTP
                      </button>
                    </div>
                  )}
                </>
              )}
            {!errors.email && otpSent && !isAuthenticated && (
              <>
                {width > 700 && (
                  <div className="flex items-end justify-end w-full">
                    <button
                      className="text-nowrap  font-semibold px-4 py-2 text-sm text-center text-[#113cc0] transition-all border border-transparent rounded-md text-slate-600 hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                      onClick={handleSendOTP}
                    >
                      Resend OTP
                    </button>
                  </div>
                )}
              </>
            )}
          </div>

          {/* <div className="mb-4">
            <label
              // for="input"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Your Label
            </label>

            <div className="flex items-center p-2 border border-gray-300 rounded-lg">
              <img src={UserIcon} width="20" height="20" />

              <input
                id="input"
                type="text"
                className="flex-1 p-2 text-gray-700 outline-none"
                placeholder="Enter your text"
              />
            </div>
          </div> */}
          {otpSent && !isAuthenticated && (
            <div className="flex justify-center gap-2">
              <OTPInput
                value={otpValue}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={false}
                renderInput={(props, index) => (
                  <input
                    {...props}
                    className={`otpInputs shadow-lg mr-2 last-of-type:mr-0 flex items-center justify-center rounded-lg text-black bg-white font-extrabold text-lg outline-none 
        ${width > 700 ? "w-10 h-10 min-h-10" : "w-8 h-8 min-h-8"}`}
                    onFocus={() => setFocusedIndex(index)}
                    onBlur={() => setFocusedIndex(null)}
                  />
                )}
              />
            </div>
          )}

          {error && <p className="text-red-400">{error?.message}</p>}

          {otpSent && !isAuthenticated && (
            <button
              type="button"
              onClick={handleVerifyOtp}
              className="transition duration-0 hover:duration-150 py-3 px-5 border rounded-full bg-[#113cc0] text-white hover:bg-green hover:text-white  ease-in-out w-48 mx-auto"
              // disabled={loading ? true : false}
            >
              Verify OTP
            </button>
          )}
          {otpSent && isAuthenticated && (
            <p className="text-xs italic text-green-600">
              OTP successfully verified
            </p>
          )}

          {/* <div className="flex justify-center mt-10">
            <button
              type="submit"
              onClick={handleNext}
              className="transition duration-0 hover:duration-150 p-3 border rounded-full bg-[#113cc0] text-white hover:bg-[#113cc0] hover:text-white  ease-in-out"
              style={{ padding: "8px 18px" }}
            >
              Register
            </button> 
          </div> */}
          <div className="flex justify-center">
            <span className="text-sm">Already have an account? </span>
            <Link
              to="/secure/login"
              className="text-[#113cc0] font-semibold bg-transparent hover:underline focus:outline-none text-sm"
              // onClick={(e) => navigate("/secure/login")}
            >
              {" "}
              Log In
            </Link>
          </div>

          <footer className="bg-transparent-800">
            <div className="flex flex-row gap-2">
              <div
                className={`w-5 h-5 min-w-5 border-2 rounded-md flex items-center justify-center cursor-pointer transition ${
                  isChecked ? "border-[#00c798]" : "border-gray-500"
                } ${
                  !isValid ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                }`}
                // onClick={handleCheckboxChange}

                onClick={isValid ? handleCheckboxChange : undefined}
              >
                {isValid && (
                  <>
                    {isChecked ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-[#00c798]"
                        size="sm"
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>

              <div className="text-sm">
                By continuing, you agree to our{" "}
                <Link
                  to="/terms-condition"
                  className="font-semibold text-[#113cc0]  underline-none hover:no-underline"
                >
                  Terms & Conditions
                </Link>{" "}
                and{" "}
                <Link
                  to="/privacy-policy"
                  className="font-semibold text-[#113cc0]  underline-none hover:no-underline"
                >
                  Privacy Policy
                </Link>
                .
              </div>
            </div>
            {otpSent && isAuthenticated && !isChecked && (
              <p className="text-xs italic text-red-600 text-right mx-6">
                Accept the terms and Conditons
              </p>
            )}
          </footer>
        </div>
      </StepNavigationWrapper>
    </div>
  );
};

export default PersonalInfoForm;
